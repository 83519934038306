<template>
	<div class="page flex-col">
		<div class="section_1 flex-row">
			<div class="group_1 flex-col" :class="startX<=600?'wid600':''">
				<div style="width: 100vw;" v-if="startX<=600">
					<head-top :type="4" :show="-1" :startX="startX"></head-top>
				</div>
				<div class="" v-else>
					<head-top :type="4" :startX="startX"></head-top>
				</div>
				<!-- <div class="block_2 flex-col"></div> -->
				<div class="block_3 flex-col" style="margin: 0 auto;">
					<div class="section1" @click.stop="toIndex(constants.banner.picture[0])">
						<img v-if="constants.banner.audio_type==0" :src="constants.banner.picture[0].abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%"
							:src="constants.banner.picture[0].abs_url"></video>
					</div>
					<!-- <el-carousel height="100%" class="section1">
						<el-carousel-item v-for="item in constants.banner.picture" :key="item.id" @click.stop="toIndex(item)">
							<img v-if="constants.banner.audio_type==0" :src="item.abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
						</el-carousel-item>
					</el-carousel> -->
					<div class="flex-col mainApp24 justify-between" style="z-index: 9;margin: 0 auto;height: 100%;"
						:style="startX<600?'margin-top: -4rem;min-width: 300px;':''">
						<div class="" style="margin-top:17% ;" :style="startX<600?'margin-top: 5%;':''">
							<div class="section_2 flex-row">
								<div class="text-wrapper_1">
									<span class="text_10">p</span>
									<span class="text_11">roduct</span>
									<span class="text_12">&nbsp;c</span>
									<span class="text_13">enter</span>
								</div>
							</div>
							<div class="text-wrapper_2 flex-row">
								<span class="text_14">产品中心</span>
							</div>
						</div>

						<div class="section_3 flex-row justify-end align-center" @click="toProduct">
							<span class="text_15">产品中心</span>
							<img class="thumbnail_2" referrerpolicy="no-referrer"
								src="./assets/img/psnyast5yo1oqbekk4ngctf4kg15npz0ti7e4b576f-6cb8-4f70-9804-fa786f2c205d.png" />
							<span class="text_16">{{titleName}}</span>
						</div>
					</div>

				</div>
				<div class="flex-row justify-center mainApp24" style="margin: 0 auto;"
					:style="startX<600?'min-width: 300px;width: 100vw;justify-content: space-between;':''">
					<div class="nav" style="" :style="startX<600?'margin-top: 0px;margin-right: 0;':''">
						<div class="navmenu" v-if="startX>600">
							<div class="navmenu-item  flex-col justify-between align-center"
								v-for="(item,index) in constants.category" :key="index">
								<div class="navmenu-item-name justify-between flex-row align-center"
									@click.stop="toChoose(item.category_id)" :class="item.choose==1?'act':''">
									<span>{{item.name}}</span>
									<img src="./assets/img/next.png" alt=""
										:style="item.choose==1?'transform: rotate(90deg);':''">
								</div>
								<div class="navmenu-item-child  flex-row align-center"
									@click.stop="toChoose1(item1.category_id)" v-show="item.choose==1"
									:class="item1.choose==1?'act':''" v-for="(item1,index1) in item._child"
									:key="index1">
									<img src="./assets/img/sensing.png" alt="" v-show="item1.choose==1">
									<div class="">{{item1.name}}</div>
								</div>
							</div>
						</div>
						<div class="navmenu1" v-else>
							<div class="navmenu1-item  flex-col justify-between align-center"
								v-for="(item,index) in constants.category" :key="index">
								<div class="navmenu1-item-name justify-center flex-row align-center"
									@click.stop="toChoose(item.category_id)" :class="item.choose==1?'act':''">
									<div class="line"> </div>
									<span>{{item.name}}</span>
								</div>
								<div class="navmenu1-item-child  flex-row align-center"
									@click.stop="toChoose1(item1.category_id)" v-show="item.choose==1"
									:class="item1.choose==1?'act':''" v-for="(item1,index1) in item._child"
									:key="index1">
									<img src="./assets/img/sensing.png" alt="" v-show="item1.choose==1">
									<div class="">{{item1.name}}</div>
								</div>
							</div>
						</div>
					</div>

					<div class=" flex-col" style="width: 80%;" :style="startX<600?'width: 77%;':''">
						<div class="text-group_1 flex-col justify-between">
							<span class="text_17">{{titleName}}</span>
							<!-- <span class="text_18">HEAVY&nbsp;INDUSTRYEQUIPMENT</span> -->
						</div>
						<div class="block_4 flex-row justify-around" id='chanPin' style="width: 100%;"
							v-if="startX>600">
							<div class="group_2 flex-col justify-between" @click="showCar(item.product_id)"
								v-for="(item,index) in proList" :id="'chanPin'+index" :key="index">
								<img class="image_2" referrerpolicy="no-referrer" :src="item.cover" />
								<div class="group_3 flex-row justify-between align-center">
									<span class="text_19">{{item.name}}</span>
									<div class="image-wrapper_1 flex-align-justify">
										<img class="thumbnail_3" refeflex-align-justifyrrerpolicy="no-referrer"
											src="./assets/img/psnxcwoqkspzfpr6u7n0pk3dp5kp29yx0vi17f136fb-88ba-433a-bd82-876580dbba77.png" />
									</div>
								</div>
							</div>
						</div>
						<div class="block_4 flex-row justify-around" style="width: 100%;" v-else>
							<div class="group_2 flex-row justify-between" @click="showCar(item.product_id)"
								v-for="(item,index) in proList" :key="index">
								<img class="image_2" referrerpolicy="no-referrer" :src="item.cover" />
								<div class="group_3 flex-row justify-between align-center">
									<span class="text_19">{{item.name}}</span>
									<div class="image-wrapper_1 flex-align-justify">
										<img class="thumbnail_3" refeflex-align-justifyrrerpolicy="no-referrer"
											src="./assets/img/psnxcwoqkspzfpr6u7n0pk3dp5kp29yx0vi17f136fb-88ba-433a-bd82-876580dbba77.png" />
									</div>
								</div>
							</div>
						</div>
						<div class="block_14 flex-row justify-end">
							<div class="text-wrapper_3 flex-align-justify" @click="toPage(1)">
								<span class="text_31">第一页</span>
							</div>
							<div class="image-wrapper_13 flex-align-justify" v-if="p!=1" @click="toPage(p-1)">
								<img class="thumbnail_15" referrerpolicy="no-referrer" src="./assets/img/prev.png" />
							</div>
							<div class="text-wrapper_4 flex-align-justify" :class="p==item?'bgBlue':''"
								v-for="(item,index) in pageList" :key="index" @click="toPage(item)">
								<span class="text_32">{{item}}</span>
							</div>
							<div class="image-wrapper_13 flex-align-justify" v-if="p!=pages" @click="toPage(p*1+1)">
								<img class="thumbnail_15" referrerpolicy="no-referrer" src="./assets/img/next.png" />
							</div>
							<div class="text-wrapper_8 flex-align-justify" @click="toPage(pages)">
								<span class="text_31">最后一页</span>
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
		<!-- <el-dialog :class="startX<=600?'el-dialog1':''" :visible.sync="dialogVisible" @close="dialogVisible=false">
			<el-carousel trigger="click" ref="carousel" :autoplay="false" class="dialog" :initial-index="showNum"
				@change="caroChange">
				<el-carousel-item v-for="(item,index) in list" :key="index" class="flex-align-justify flex-col">
					<img :src="require('./assets/img/'+item.image)" class="car_img" alt="">
					<div class="text1">{{ item.name }}</div>
					<div class="text2">{{ item.time }}</div>
				</el-carousel-item>
			</el-carousel>
			<div></div>
		</el-dialog> -->
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue";
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		product,
		productList
	} from "../../util/api";
	export default {
		data() {
			return {
				constants: {
					banner: {
						picture: []
					}
				},
				titleName: '',
				dialogVisible: false,
				list: [{
					image: 'dabi.png',
					name: "大臂",
					time: "发布于：2015-7-10 15:19:08 "
				}, {
					image: 'xiaobi.png',
					name: "小臂",
					time: "发布于：2015-7-10 18:19:08 "
				}, {
					image: 'chandou.png',
					name: "铲斗",
					time: "发布于：2015-8-10 18:19:08 "
				}],
				showNum: 1,
				startX: 1200,
				// category_id: '',
				p: 1,
				pages: 2,
				shoCat: 0,
				pageList: [],
				proList: [],
				activeName: 1
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1
		},
		mounted() {
			// this.category_id = this.$route.params.id;
			// console.log(this.$route.params);
			this.getDate()
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
			window.addEventListener("scroll", this.scrollHandle, true);
		},
		deforeDestroy() {
			window.removeEventListener('scroll', this.scrollHandle, true);
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					// console.log(this.startX, "屏幕宽度");
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
			category_id(val, oldval) {
				if (val != oldval) {
					this.getDate()
					if(this.startX>600){
						document.documentElement.scrollTop = 500;
					}
					
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
			category_id() {
				console.log(this.$route.params.id, "id");
				return this.$route.params.id
			}
		},
		methods: {
			toProduct() {
				this.$router.push("/product/1");
			},
			getDate() {
				// if (this.category_id == '' || this.category_id == undefined) {
				// 	this.category_id == ''
				// }
				product({
					lang: this.lang,
					category_id: this.category_id
				}).then((res) => {
					// console.log(res);
					this.constants = res.data
					// if (this.category_id == '' || this.category_id == undefined) {
					// 	this.category_id = this.constants.category[0].category_id
					// }
					this.getlist()
					let name
					for(let i =0;i<this.constants.category.length;i++){
						let ele =this.constants.category[i]
						console.log(ele);
						if (ele.choose == 1) {
							if (ele._child.length > 0) {
								for(let j =0;j<ele._child.length;j++){
									let ele1 =ele._child[j]
									console.log(ele1);
									if (ele1.choose == 1) {
										name = ele1.name
										break
									} else {
										name = ele.name
										console.log(name,"ele");
									}
								}
							}
						}
					}
					console.log(name,"ele1");
					this.titleName = name
					console.log(name);
					this.$forceUpdate()
					// this.title = res.data.header_nav;
				});

			},
			getlist() {
				productList({
					lang: this.lang,
					category_id: this.category_id,
					p: this.p
				}).then((res) => {
					console.log(res);
					this.proList = res.data.list
					this.p = res.data.p
					this.pages = res.data.pages * 1
					console.log(this.pages);
					let list = []
					if (this.p == 1) {
						for (let i = 1; i <= this.pages; i++) {
							if (i < 6) {
								list.push(i)
							}
						}
					} else {
						list.push(this.p - 1)
						for (let i = this.p; i <= this.pages; i++) {
							if (i < (this.p + 4)) {
								list.push(i)
							}
						}
					}
					this.pageList = list
					if (this.p != 1) {
						document.documentElement.scrollTop = 300;
					}
				});
			},
			toPage(num) {
				this.p = num
				this.getlist()
			},
			toIndex(v) {
				if (v.target_rule == 1) {
					this.$router.push("/");
				} else if (v.target_rule == 2) {
					this.$router.push("/aboutUs");
				} else if (v.target_rule == 3) {
					let id = v.param
					this.$router.push("/news/" + id);
				} else if (v.target_rule == 4) {
					let news_id = v.param
					this.$router.push("/newDetail/" + news_id);
				} else if (v.target_rule == 5) {
					let id = v.param
					console.log(id);
					this.$router.push("/product/" + id);
				} else if (v.target_rule == 6) {
					this.$router.push("/companies");
				} else if (v.target_rule == 7) {
					this.$router.push("/Investor");
				} else if (v.target_rule == 8) {
					this.$router.push("/invite");
				} else if (v.target_rule == 9) {
					this.$router.push("/contact");
				}
			},
			toChoose(id) {
				this.p = 1
				// this.category_id=id
				// this.getDate()
				this.$router.push("/product/" + id);
			},
			toChoose1(id) {
				this.p = 1
				// this.category_id=id
				// this.getDate()
				this.$router.push("/product/" + id);
			},
			caroChange(e) {
				console.log(e)
			},
			showCar(id) {
				this.$router.push("/productDetail/" + this.category_id + '/' + id);
				// this.showNum = i
				// this.dialogVisible = true
				// this.$refs.carousel.setActiveItem(this.showNum);
			},
			scrollHandle() {
				let chanPin = this.isInViewPortOfOne(document.getElementById('chanPin'))
				for (let i = 0; i < this.proList.length; i++) {
					let id = 'chanPin' + i
					if (chanPin) {
						setTimeout(() => {
							document.getElementById(id).style.opacity = '1'
						}, i * 300 + 300)
					} else {
						document.getElementById(id).style.opacity = '0'
					}
				}
			},
			isInViewPortOfOne(el) {
				// viewPortHeight 兼容所有浏览器写法
				const offset = el.getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					return true
				} else {
					return false
					// console.log('移出可视区域');
				}
			},
		},
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss" scoped>
	::v-deep .van-icon {
		display: none;
	}

	.wid600 {
		width: 100vw;

		.block_3 {
			width: 100vw;
			min-width: 66.667rem;
			max-width: 200rem;
			// height: 18vw;
			min-height: 12rem;
			max-height: 36.333rem;
			// padding: 0 7.667rem;
			margin-top: 13.333rem;

			.section1 {
				// width: auto;
				min-height: auto;
			}

			.section_2 {
				width: auto;
				height: auto;
				margin: 0;
				margin-top: 13.667rem;

				.text-wrapper_1 {
					font-size: 6rem;
					font-weight: normal;
					line-height: 5.333rem;
					text-transform: uppercase;
				}
			}

			.text-wrapper_2 {
				width: auto;
				height: auto;
				// cursor: pointer;
				margin: 0;
				margin-top: 2rem;

				.text_14 {
					width: auto;
					height: auto;
					font-size: 4rem;
					line-height: 5.333rem;
				}
			}

			.section_3 {
				width: auto;
				height: auto;
				margin: 0;
				margin-top: 2.333rem;
				justify-content: flex-end;

				.text_15 {
					width: auto;
					height: auto;
					font-size: 3.333rem;
					line-height: 3.333rem;
					cursor: pointer;
				}

				.thumbnail_2 {
					width: 1rem;
					height: 1.667rem;
					margin: 0 2rem;
				}

				.text_16 {
					width: auto;
					height: auto;
					font-size: 3.333rem;
					line-height: 3.333rem;
					margin: 0;
				}
			}
		}

		.navmenu {
			width: 220px;
			border-radius: 10px 10px 0px 0px;

			&-item {
				width: 100%;

				&-child {
					width: 100%;
				}
			}

			.act {
				background: #0390CF;
			}
		}

		.text-group_1 {
			width: 100%;
			height: auto;
			margin: 9.667rem 0 0 5.667rem;

			.text_17 {
				width: auto;
				height: auto;
				font-size: 4.333rem;
				line-height: 4.4rem;
			}

			.text_18 {
				width: auto;
				height: auto;
				font-size: 3rem;
				line-height: 3rem;
				margin-top: 2.5rem;
			}
		}

		.block_4 {
			width: 92%;
			grid-template-columns: repeat(2, 1fr);
			margin-top: 6.333rem;

			.group_2 {
				width: 100%;
				// margin-right: 3.333rem;
				height: auto;
				justify-content: center;

				.image_2 {
					width: 45rem;
					height: auto;
				}

				.group_3 {
					// width: 92%;
					// height: auto;
					// margin: 4rem 0 0 4rem;
					width: 37%;
					height: auto;
					margin: 3rem 0 0 4rem;
					align-items: flex-start;
					justify-content: flex-start;
					position: relative;

					.text_19 {
						font-size: 3rem;
						line-height: 3rem;
					}

					.image-wrapper_1 {
						width: 5.667rem;
						height: 5.7rem;
						position: absolute;
						right: 0;
						bottom: 0;

						.thumbnail_3 {
							width: 2.5rem;
							height: 1.333rem;
						}
					}
				}
			}
		}

		.block_14 {
			width: 100%;
			height: auto;
			margin: 11rem auto;
			justify-content: center;

			.text-wrapper_3 {
				width: 12.333rem;
				height: 6.667rem;
				border-radius: 0.667rem;

				.text_31 {
					font-size: 2.667rem;
					line-height: 2.667rem;
				}
			}

			.image-wrapper_13 {
				width: 6.667rem;
				height: 6.667rem;
				margin-left: 3.333rem;

				.thumbnail_15 {
					width: 1.12rem;
					height: 2rem;
				}
			}

			.text-wrapper_4 {
				width: 6.667rem;
				height: 6.667rem;
				margin-left: 3.333rem;
				border-radius: 0.667rem;

				.text_32 {
					font-size: 2.667rem;
				}
			}

			.text-wrapper_8 {
				width: 15rem;
				height: 6.667rem;
				margin-left: 3.333rem;
				border-radius: 0.667rem;

				.text_31 {
					font-size: 2.667rem;
					line-height: 2.667rem;
				}
			}
		}

	}

	.el-dialog1 ::v-deep .el-dialog {
		width: 100%;
		margin-top: 20vh !important;

		.dialog {
			height: 85rem;

			.el-carousel__container {
				height: 85rem;

				.el-carousel__arrow {
					top: 39%;
					height: 8rem;
					width: 8rem;
				}

				.car_img {
					width: 85%;
					height: auto;
				}

				.text1 {
					font-size: 4.333rem;
					margin-top: 6.667rem;
				}

				.text2 {
					font-size: 3rem;
					margin-top: 2.5rem;
				}
			}
		}

	}
</style>